<template>
  <div class="card" style="border: none;" id="print-lab-report">
    <div class="card-body">
      <div class="row justify-content-center">
        <div class="col-md-8 text-center">
          <img src="/images/hayandra.jpeg" height="60" />
          <h6 class="font-weight-light" style="font-size: 11pt">{{ setting.address }}</h6>
          <h6 class="font-weight-light" style="font-size: 11pt">{{ setting.emergency }}</h6>
        </div>
      </div>
      <div class="text-center mt-9">
        <h4
          style="font-weight: bold; text-transform: uppercase; font-size: 14px;"
        >
          Laporan Lab
          <hr
            style="
              border-bottom: 2px solid black;
              margin-right: 200px;
              margin-left: 200px;
              margin-top: 1px;
              margin-bottom: 5px;
            "
          />
        </h4>
      </div>
      <div class="d-flex justify-content-between mt-5" style="font-size: 12px">
        <div class="col-md-6">
          <table class="font-weight-bold">
            <tr>
              <td style="width: 100px; padding: 1.3px">Nama Pasien</td>
              <td>: {{ data.patient_name }}</td>
            </tr>
            <tr>
              <td style="width: 100px; padding: 1.3px">ID Pasien</td>
              <td>: {{ data.patient_id_card_number }}</td>
            </tr>
            <tr>
              <td style="width: 100px; padding: 1.3px">Nomor HP</td>
              <td>: {{ data.patient_mobile_phone }}</td>
            </tr>
            <tr>
              <td style="width: 100px; padding: 1.3px">Alamat</td>
              <td>: {{ data.patient_address }}</td>
            </tr>
          </table>
        </div>
        <div class="float-right">
          <table class="font-weight-bold">
            <tr>
              <td style="width: 100px; padding: 1.3px">No Laporan</td>
              <td>: {{ data.id }}</td>
            </tr>
            <tr>
              <td style="width: 100px; padding: 1.3px">Tanggal</td>
              <td>: {{ data.display_date }}</td>
            </tr>
            <tr>
              <td style="width: 100px; padding: 1.3px">Dokter</td>
              <td>: {{ data.doctor_name }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="w-100" :style="`height: ${height}`">
        <div class="editor-container mt-3" style="width: 100%" v-html="data.report"></div>
      </div>
      <div class="d-flex justify-content-start mt-25">
        <div class="col-md-3">
          <span style="font-size: 12px; font-weight: light"
            >Jakarta, {{ data.display_date }}</span
          >
        </div>
      </div>
      <div class="d-flex justify-content-between mt-25 mb-0">
        <div class="col-md-7">
          <h5 style="font-size: 13px; font-weight: bold">
            Dr.dr.Karina.,Sp.BP-RE
          </h5>
          <span>(Kepala Laboratorium)</span>
        </div>
        <div class="col-md-4">
          <span>Penyusun: {{data.created_by_name}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    data: Object,
    height: String,
    setting: Object,
  },

  methods: {
    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf();
    },

    btnPrintOnClick() {
      this.$htmlToPaper("print-lab-report");
    },    
  },
};
</script>

<style>
figure.table table {
  width: 100% !important;
}
</style>