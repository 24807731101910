<template>
  <div class="row">

    <div class="col-12">
      <b-table
        striped
        hover
        responsive
        class="mt-3"
        :items="items"
        :fields="fields"
      >
      <template #cell(date)="data">
        {{ new Date(data.item.date).toLocaleDateString('id-ID', { weekday: 'long', day: 'numeric', month: 'numeric', year: 'numeric' }) }}
      </template>
        <template #cell(actions)>
          <b-button
            size="sm"
            class="mr-1 btn-info"
            v-b-tooltip.hover
            title="Detail"
          ><i class="fas fa-eye px-0"></i></b-button>
        </template>
      </b-table>
      <b-pagination
        v-if="items.length != 0"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        @page-click="pageOnClick"
        class="mt-4"
      ></b-pagination>
    </div>

  </div>
</template>

<script>

import module from '@/core/modules/CrudModule.js'

export default {

  name: 'TimelineHistory',

  data() {
    return {
      // pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // table fields
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "description",
          label: "#",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // table items
      items: [],
    }
  },

  methods: {

    pageOnClick(evt, page) {
      evt.preventDefault()
      this.currentPage = page
      this.pagination()
    },

    async pagination() {
      let response = await module.paginate(`patient-timelines/${this.$route.params.id}`, `?page=${this.currentPage}&page_size=${this.perPage}`)
      let pagination = response.meta.pagination
      this.items = response.data
      console.log(response.data)
      this.totalRows = pagination.total
    },

  },

  mounted() {
    this.pagination()
  }

}
</script>

<style>
</style>