<template>
  <div>

    <b-modal
      id="modal-form"
      title="Tambah Kasus"
      @ok="modalOnSubmit"
      @hide="modalOnHide"
    >
      <b-form @submit.stop.prevent="formOnSubmit">
        <!-- Date Picker -->
        <b-form-group
          id="input-group-date"
          label="Tanggal:"
          label-for="input-date"
        >
          <b-form-datepicker
            id="input-date"
            v-model="form.date"
            placeholder="Pilih Tanggal"
            locale="id"
          ></b-form-datepicker>
          <small class="text-danger">{{ error.date }}</small>
        </b-form-group>

        <!-- Title input -->
        <b-form-group
          id="input-group-title"
          label="Kasus:"
          label-for="input-title"
        >
          <b-form-input
            id="input-title"
            v-model="form.title"
            placeholder="Kasus"
          ></b-form-input>
          <small class="text-danger">{{ error.title }}</small>
        </b-form-group>

        <!-- Description input -->
        <b-form-group
          id="input-group-description"
          label="Keterangan:"
          label-for="input-description"
        >
          <b-form-textarea
            id="input-description"
            v-model="form.case"
            placeholder="Keterangan"
            rows="4"
            max-rows="8"
          ></b-form-textarea>
          <small class="text-danger">{{ error.case }}</small>
        </b-form-group>
      </b-form>
    </b-modal>

  </div>
</template>

<script>

import validation from '@/core/modules/ValidationModule.js'
import module from '@/core/modules/CrudModule.js'

export default {

  props: {
    form: Object,
    route: String,
  },

  data() {
    return {
      // Error
      error: {
        date: '',
        title: '',
        case: ''
      },
    }
  },

  methods: {

    async formOnSubmit() {
      let response = await module.submit(this.form, this.route)
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, 'success')
        // Hide Modal
        this.$bvModal.hide('modal-form')
        // Refresh Table
        this.$emit('formOnSubmit')
      }
    },

    modalOnSubmit(evt) {
      evt.preventDefault()
      this.formOnSubmit()
    },

    modalOnHide(evt) {
      this.error = validation.clearValidationError(this.error)
      this.form = validation.clearForm(this.form)
    }

  }

}
</script>

<style>
</style>