<template>
  <div>

    <Table
      :items="items"
      :fields="fields"
      purpose="patient-history"
      :hasPatient="true"
      :perPage="perPage"
      :currentPage="currentPage"
      :totalRows="totalRows"
      @filterByDoctor="filterByDoctor"
      @btnDownloadOnClick="btnDownloadNowOnClick"
      @btnDetailOnClick="btnDetailOnClick"
      @pageOnClick="pageOnClick"
    />

    <b-modal
      id="modal-print-lab"
      hide-footer
      size="xl"
      title="Preview Laporan"
    >
      <div class="row">
        <div class="col-md-8">
          <Print
            :data="data"
            height="800px"
            :setting="setting"
          />
        </div>
        <div class="col-md-4">
          <b-button
            squared
            class="mt-2"
            size="sm"
            variant="primary"
            @click="btnPrintOnClick"
          >Print</b-button>
          <b-button
            squared
            :class="'d-block mt-2'"
            size="sm"
            variant="info"
            @click="btnDownloadOnClick"
          >Download</b-button>
        </div>
      </div>
    </b-modal>

    <!-- PDF -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="`test-laboratorium-${data.patient_name}`"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <Print
          :data="data"
          height="470px"
          :setting="setting"
        />
      </section>
    </vue-html2pdf>

  </div>
</template>

<script>

import Table from '@/component/lab-reports/Table.vue'
import Print from '@/component/lab-reports/Print.vue'
import VueHtml2pdf from "vue-html2pdf"
import module from '@/core/modules/CrudModule.js'

export default {

  name: 'LaboratoriumHistory',

  components: {
    Table,
    VueHtml2pdf,
    Print
  },

  data() {
    return {
      // Filter
      filter: {
        doctor_id: ''
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Fields
      fields: [
        {
          key: "number",
          label: '#',
          sortable: true,
        },
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "doctor_name",
          label: "Dokter",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      setting: {},
      // Lab Report Detail
      data: {}
    }
  },

  methods: {

    async getSetting() {
      this.setting = await module.get('website-settings/get-default')
      if (this.setting == null) {
        this.setting = {
          address: "Jl. Kramat 6 No.11, RT.2/RW.1, Kenari, Kec. Senen, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10430",
          emergency: "(021) 3909333"
        }
      }
    },

    pageOnClick(evt, page) {
      evt.preventDefault()
      this.currentPage = page
      this.pagination()
    },

    async pagination() {
      let filterParams = `&doctor_id=${this.filter.doctor_id}`
      let response = await module.paginate(`lab-reports/get-by-patient/${this.$route.params.id}`, `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      this.items = response.data
      this.totalRows = pagination.total
    },

    filterByDoctor(sended) {
      this.filter.doctor_id = sended.doctor_id
      this.pagination()
    },

    async getData(id) {
      this.data = await module.get(`lab-reports/${id}`)
    },

    btnPrintOnClick() {
      this.$htmlToPaper('print-lab-report')
    },

    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf()
    },

    btnDetailOnClick(id) {
      this.getData(id)
    },

    async btnDownloadNowOnClick(id) {
      await this.getData(id)
      this.$refs.html2Pdf.generatePdf()
    },

    onProgress($event) {
      console.log("onProgress", $event)
    },

    hasStartedGeneration() {
      console.log("hasStartedGeneration")
    },

    hasGenerated($event) {
      console.log("hasGenerated", $event)
    },

  },

  mounted() {
    // Get Table Data
    this.getSetting()
    this.pagination()
  },

}
</script>

<style>
</style>