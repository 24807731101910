<template>
  <div class="card" id="print" style="border: none">
    <div class="card-body pt-4">
      <div class="main-prescription" style="min-height: 980px">
        <div class="row align-items-center">
          <div class="col-md">
            <h2 class="doctor">
              <h5>{{ data.doctor_name }}</h5>
            </h2>
            <h4>{{ doctor.polyclinic_name }}</h4>
          </div>
          <div class="col-md text-right">
            <img src="/images/hayandra.jpeg" height="60" />
          </div>
        </div>
        <div class="panel-body mt-5">
          <table class="table">
            <tr>
              <td>Tanggal: {{ data.display_date }}</td>
              <td>Resep ID: {{ data.id }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Pasien: {{ data.patient_name }}</td>
              <td>Pasien ID: {{ data.patient_number }}</td>
              <td>Umur: {{ patientAge }}</td>
              <td>Kelamin: {{ patient.gender }}</td>
            </tr>
          </table>
        </div>
        <hr />
        <div class="col-md-12 clearfix" style="margin: 25px 0px">
          <div class="row">
            <div class="col-md-12">
              <div class="panel-body">
                <div>
                  <table class="table">
                    <tr>
                      <th>Nama</th>
                      <th>Dosis</th>
                      <th>Frekuensi</th>
                      <th>Hari</th>
                      <th>Instruksi</th>
                    </tr>
                    <tr v-for="mdc in displayMedicine" :key="mdc.id">
                      <td>{{ mdc.name }}</td>
                      <td>{{ mdc.dosage }}</td>
                      <td>{{ mdc.frequency }}</td>
                      <td>{{ mdc.days }}</td>
                      <td>{{ mdc.instruction }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-md-4 pull-left"
          style="font-size: 12px; margin-top: 70px"
        >
          <hr />
          Tanda tangan
        </div>
        <div class="col-md-8 pull-right text-right mt-10">
          <h6 class="font-weight-light" style="font-size: 11.4px">
            {{ setting.address }}
          </h6>
          <h6 class="font-weight-light" style="font-size: 11.4px">
            {{ setting.phone }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: String,
    data: Object,
    doctor: Object,
    patient: Object,
    patientAge: Number,
    splitMedicine: String,
    strMedicine: String,
    displayMedicine: Array,
    setting: Object,
  },
};
</script>

<style scoped>
hr {
  margin-top: 0px;
  margin-bottom: 0px;
  border: 0;
  border-top: 1px solid #000;
}

.panel-body {
  background: #f1f2f7;
}

thead {
  background: transparent;
}

.bg_prescription {
  min-height: 760px;
  margin-top: 10px;
}

.prescription_footer {
  margin-bottom: 10px;
}

.bg_container {
  border: 1px solid #f1f1f1;
}

.panel {
  background: #fff;
}

.panel-body {
  background: #fff;
}

.margin_top {
  margin-top: 65px;
}

.wrapper {
  margin: 0px;
  padding: 60px 30px 0px 30px;
}

.doctor {
  color: #2f80bf;
}

.hospital {
  color: #2f80bf;
}

hr {
  border-top: 1px solid #f1f1f1;
}

.panel_button {
  margin: 10px;
}

.left_panel {
  border-right: 1px solid #ccc;
  margin-left: -15px;
}

th {
  border-bottom: 0px;
}

.col-md-4 {
  margin-right: 0px !important;
}

.patient {
  font-size: 12px;
}

.patient_name {
  font-size: 12px;
}

.prescription {
  font-size: 12px;
}

p {
  font-size: 12px;
}

.top_logo {
  margin-bottom: 10px;
}

/* @media print {

  .left_panel {
    border-right: 1px solid #ccc;
    margin-left: -15px;
  }

  .wrapper {
    margin: 0px;
    padding: 0px 10px 0px 0px;
  }

  .patient {
    width: 23%;
    float: left;
  }

  .patient_name {
    width: 31%;
    float: left;
  }

  .text-right {
    float: right;
  }

  .doctor {
    color: #2f80bf !important;
    font-size: 25px;
  }

  .hospital {
    color: #2f80bf !important;
  }

  .prescription {
    float: left;
  }

  .top_title {
    width: 70%;
  }

  .top_logo {
    width: 30%;
    margin-bottom: 10px;
  }

  .col-md-6 {
    width: 50%;
    float: left;
  }

  .col-md-5 {
    width: 45%;
    float: left;
  }

  .col-md-7 {
    width: 55%;
    float: left;
  }

  th {
    border-bottom: 0px;
  }
} */
</style>