<template>
  <div>

    <Table
      :items="items"
      :fields="fields"
      :hasPatient="true"
      :perPage="perPage"
      :currentPage="currentPage"
      :totalRows="totalRows"
      @btnDeleteOnClick="btnDeleteOnClick"
      @appointmentOnStatusChange="pagination"
      @filter="generalFilter"
      @pageOnClick="pageOnClick"
    />

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import Table from '@/component/appointments/Table.vue'
import module from '@/core/modules/CrudModule.js'

export default {

  components: {
    Table,
    Card
  },

  data() {
    return {
      // Filter
      filter: {
        appointment_status_id: '',
        doctor_id: '',
        start_date: '',
        end_date: ''
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "time",
          label: "Waktu",
          sortable: true,
        },
        // {
        //   key: "doctor_name",
        //   label: "Dokter",
        //   sortable: true,
        // },
        {
          key: "doctor_name_action_type_name",
          label: "Dokter & Jenis Tindakan",
          sortable: true,
        },
        // {
        //   key: "action_type_name",
        //   label: "Jenis Tindakan",
        //   sortable: true,
        // },
        {
          key: "appointment_status_name",
          label: "Status",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
    }
  },

  methods: {

    pageOnClick(evt, page) {
      evt.preventDefault()
      this.currentPage = page
      this.pagination()
    },

    async pagination() {
      let filterParams = `&appointment_status_id=${this.filter.appointment_status_id}&doctor_id=${this.filter.doctor_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`
      let response = await module.paginate(`appointments/get-by-patient/${this.$route.params.id}`, `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      this.items = response.data
      this.totalRows = pagination.total
    },

    async generalFilter(sended) {
      this.filter.appointment_status_id = sended.appointment_status_id
      this.filter.doctor_id = sended.doctor_id
      this.filter.start_date = sended.start_date
      this.filter.end_date = sended.end_date
      this.pagination()
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete('appointments/' + id)
      // If Deleted
      if (result) {
        this.pagination()
      }
    },

  },

  mounted() {
    // Table List
    this.pagination()
  }

}
</script>

<style>
</style>