<template>
  <div>

    <!-- Filter -->
    <div class="row align-items-center justify-content-start mt-3">
      <div class="col-md-9 col-12">
        <b-input-group>
          <template #prepend>
            <b-button
              squared
              @click="resetFilterPeriode"
              variant="danger">Reset</b-button>
            <b-button
              squared
              @click="filterByPeriode"
              variant="success">Cari</b-button>
          </template>
          <b-form-input
            v-model="filter.start_date"
            type="text"
            placeholder="Tanggal Awal"
            autocomplete="off"
            readonly></b-form-input>
          <b-input-group-append>
            <b-form-datepicker
              v-model="filter.start_date"
              button-only
              right
              reset-button
              locale="id"></b-form-datepicker>
          </b-input-group-append>
          <b-button
            squared
            variant="light">s/d</b-button>
          <b-form-input
            v-model="filter.end_date"
            type="text"
            placeholder="Tanggal Akhir"
            autocomplete="off"
            readonly></b-form-input>
          <b-input-group-append>
            <b-form-datepicker
              v-model="filter.end_date"
              button-only
              right
              reset-button
              locale="id"></b-form-datepicker>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="col-md-3 col-12">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.name"
            placeholder="Cari Berdasar Uraian"
            @keyup="filterByName"></b-form-input>
        </b-input-group>
      </div>
    </div>

    <!-- Table -->
    <b-table
      striped
      hover
      responsive
      style="white-space: nowrap"
      class="mt-3"
      :items="items"
      :fields="fields">
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? '15%' : '' }" />
      </template>
      <template #cell(display_date)='data'>
        <span v-if="data.item.time == '00:00'">{{ new Date(data.item.date).toLocaleString('id-ID', { weekday: 'long', day: 'numeric', month: 'numeric', year: 'numeric' }) }}</span>
        <span v-if="data.item.time != '00:00'">{{ new Date(data.item.date).toLocaleString('id-ID', { weekday: 'long', day: 'numeric', month: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit' }) }}</span>
      </template>
      <template #cell(actions)="data">
        <b-button
          size="sm"
          class="mr-1 btn-info"
          v-b-tooltip.hover
          title="Detail"
          v-b-modal.modal-detail
          @click="btnDetailOnClick(data.item.id)"
          placement="bottom"><i class="fas fa-eye px-0"></i></b-button>
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"></b-pagination>

    <b-modal
      id="modal-detail"
      title="Detail Rekam Medis"
      size="lg"
      hide-footer>
      <div class="container">
        <table class="table mb-3">
          <tr>
            <td><strong>Tanggal</strong></td>
            <td>{{ detail.display_date }}</td>
          </tr>
          <tr>
            <td><strong>Uraian </strong></td>
            <td>{{ detail.title }}</td>
          </tr>
          <tr>
            <td><strong>Nama Pasien</strong></td>
            <td>{{ detail.patient_name }}</td>
          </tr>
          <tr>
            <td><strong>Nomor Telepon Pasien</strong></td>
            <td>{{ detail.patient_mobile_phone }}</td>
          </tr>
          <tr>
            <td><strong>Alamat Pasien</strong></td>
            <td>{{ detail.patient_address }}</td>
          </tr>
          <tr>
            <td><strong>Instruksi</strong></td>
            <td>{{ detail.case }}</td>
          </tr>
          <tr>
            <td><strong>Dokter</strong></td>
            <td>
              <ul>
                <li
                  v-for="(doctor_name, index) in detail.doctor_name"
                  :key="index">
                  {{ doctor_name }}
                </li>
              </ul>
            </td>
          </tr>
        </table>

        <div class="row">
          <div class="col-lg-12">
            <h6 class="d-block">Lampiran Dokumen :</h6>
            <div
              v-for="(attachments, index) in displayAttachment"
              :key="index">
              <a
                :href="displayAttachment[index]"
                download="file">
                <b-card
                  class="m-2 shadow-sm"
                  v-if="displayAttachment.length >= 1 && displayAttachment[0] != ''">
                  <b-card-text class="d-flex justify-content-start">
                    <i class="fas fa-file mr-2 d-inline"></i>
                    <h5>
                      {{ detail.title }}
                    </h5>
                  </b-card-text>
                </b-card>
              </a>
            </div>
          </div>
          <div class="col-lg-12">
            <h6 class="d-block">Lampiran Gambar :</h6>
            <div class="col-lg-12">
              <div class="row">
                <div
                  class="col-md-4"
                  v-for="(display, index) in displayImage"
                  :key="display">
                  <a
                    :href="displayImage[index]"
                    download="file"
                    target="_blank">
                    <img
                      class="image"
                      :src="displayImage[index]"
                      style="position:relative !important">
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>

import { debounce } from '@/core/modules/Helper.js'
import module from '@/core/modules/CrudModule.js'
import validation from '@/core/modules/ValidationModule.js'

export default {

  props: {
    items: Array,
    perPage: Number,
    currentPage: Number,
    totalRows: Number
  },

  data() {
    return {
      // Filter
      filter: {
        name: '',
        start_date: '',
        end_date: ''
      },
      // Table Fields
      fields: [
        {
          key: "number",
          label: '#',
          sortable: true,
        },
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "title",
          label: "Uraian",
          sortable: true,
        },
        {
          key: "case",
          label: "Instruksi",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Detail Data
      attachment: [],
      displayImage: [],
      displayAttachment: [],
      edit: {},
      detail: {},
      date: '',
      time: '',
      display: {
        patient_name: ''
      }
    }
  },

  methods: {

    pageOnClick(evt, page) {
      evt.preventDefault()
      this.$emit('pageOnClick', page)
    },

    resetFilter() {
      this.filter.start_date = ''
      this.filter.end_date = ''
    },

    resetFilterPeriode() {
      this.filter = validation.clearForm(this.filter)
      this.$emit('filter', this.filter)
    },

    filterByPeriode() {
      this.$emit('filter', this.filter)
    },

    filterByName() {
      debounce(() => {
        this.resetFilter()
        this.$emit('filterByName', this.filter)
      }, 500)
    },

    btnAddOnClick() {
      this.$emit('btnAddOnClick')
    },

    btnEditOnClick(id) {
      this.$emit('btnEditOnClick', id)
    },

    btnDetailOnClick(id) {
      this.getMedicalRecord(id)
    },

    btnDeleteOnClick(id) {
      this.$emit('btnDeleteOnClick', id)
    },

    async getMedicalRecord(id) {
      this.attachment = []
      this.displayImage = []
      this.displayAttachment = []
      let response = await module.get("medical-records/" + id)
      if (response) {
        if (response.attachment != null) {
          let attach = response.attachment.split(",");
          for (let a = 0; a < attach.length; a++) {
            let dataAttach = attach[a].split(".")
            if (dataAttach.length > 1) {
              if (dataAttach[dataAttach.length - 1] === "jpg") {
                this.displayImage.push(attach[a])
              } else if (dataAttach[dataAttach.length - 1] === "png") {
                this.displayImage.push(attach[a])
              } else if (dataAttach[dataAttach.length - 1] === "jpeg") {
                this.displayImage.push(attach[a])
              } else {
                this.displayAttachment.push(attach[a])
              }
            } else {
              this.displayImage.push(attach[a])
            }
          }
          this.attachment = attach;
        }
        this.edit = response;
        this.detail = response;
        let dateTime = response.date.split(" ")
        this.date = dateTime[0]
        this.time = dateTime[1]
        this.display.patient_name = response.patient_name
        console.log(this.detail)
      }
    },

  },

}
</script>

<style></style>